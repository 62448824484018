@tailwind base;
@tailwind components;
@tailwind utilities;

body {background-color: #F7FAFC
}

.coverImage {
  /* background-image: url(@/assets/images/cristina-gottardi-t7lwY83S1ec-unsplash.jpg) */
  background-image: url(@/assets/images/asoggetti-mHmKZ-lJ1Y8-unsplash.jpg)


}
